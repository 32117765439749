/* #root, */
/* body, */

html,
.root,
.body,
.app {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(90deg, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  /* background-color: antiquewhite; */
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  background-image: linear-gradient(90deg, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.forLogin {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(90deg, #45b8fb, #6d87fa, #9a53fa);
}

/* .bodyCss {
  margin: 0 0 0 0 !important;
  display: block !important;
} */

.app {
  display: flex;
  position: relative;
}

.boxStyle {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
  /* margin-top: .5rem !important; */
  border: 2px solid black;
  /* border-radius: 20px; */
  /* margin: 3px; */
  align-items: center;
  background-color: #f7f7f7;
  border: 3px solid #f2f2f2;
  border-radius: 15px;
  margin-top: 25%;
  /* margin-bottom: 25px; */
  /* margin: 31px; */
  /* width: auto; */
  width: 75%;
  height: 70vh;
  padding: 12px;
}

.successmsg {
  display: flex;
  justify-content: center;
  color: green;
  font-size: larger;
}

.errmsg {
  display: flex;
  justify-content: center;
  color: red;
  font-size: larger;
}

/* login page */
.loginMain {
  /* display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  margin: auto;
  border-radius: 15px;
  width: 556px;
  height: 75vh;
  border: 3px solid #ebdfdf;
  background-color: #f3f6f9;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center; */


  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 10px;
  margin: auto;
  border-radius: 15px;
  width: inherit;
  height: inherit;
  /* border: 3px solid #ebdfdf; */
  /* background-color: #f3f6f9; */
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(90deg, #45b8fb, #6d87fa, #9a53fa);
}




.loginpage {


  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  /* margin: 25px; */
  border: 3px solid #ebdfdf;
  background-color: #f3f6f9;
  padding: 84px 138px;
  border-radius: 15px;

  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  align-items: center; */
}

.singcolour {
  display: flex;
  align-content: center;
  color: black;
  align-items: center;
  padding: 15px;
  margin: 17px;
  border-radius: 11px;
  justify-content: space-around;
  font-size: xx-large;
  font-family: auto;
}

.sign {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 15px;
  margin: 17px;
  border-radius: 11px;
  justify-content: space-around;
  font-size: larger;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.loginButton {
  display: flex;
  border: none;
  color: white;
  padding: 9px 53px;
  text-align: center;
  font-size: 30px;
  border-radius: 10px;
  margin-top: 65px;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.errorMessage {
  display: flex;
  color: rgb(211, 49, 49);
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 49px;
  font-size: x-large;
}

::-webkit-scrollbar {
  width: 10px;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  background-color: none !important;
}

/* for dialoge box selection start */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #e5d9c9 !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* max-height: calc(100% - 64px);
  max-width: 600px; */
}

/* icon css inside bar */
.iconCss {
  border: 1.5px solid white !important;
  border-radius: 50% !important;
  padding: 3px !important;
  color: white;
}

.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  /* color: white !important; */
}

/* for contact component dialog */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {

  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 40% !important;
  width: 60% !important; */
}

.css-hlj6pa-MuiDialogActions-root {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 8px !important;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  -webkit-flex: 0 0 auto !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  align-content: center !important;
}

/* for dialoge box selection End */
.titleClass {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;


  padding: 14px;
  color: white;
  border-radius: 12px;
  margin: 20px;
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: center;
}

.userDetalis {
  /* padding: 14px;
  margin: auto;
  border-radius: 10px;
  background-color: #f7f7f7;
  width: 80%;
  border: 3px solid #f2f2f2;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

  padding: 14px;
  margin: auto;
  border-radius: 10px;
  /* background-color: #f7f7f7; */
  /* width: 80%; */
  /* border: 3px solid #f2f2f2; */
  text-align: center;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(to right, rgb(69, 184, 251), rgb(109, 135, 250), rgb(154, 83, 250));
  /* padding: 24px; */
  /* color: white; */
  align-items: center;
  font-size: large;

}

.tableCss {
  /* display: flex; */
  width: 100%;
}

.thead {
  display: flex;
  /* margin: 0 10px 0 11px; */
  /* align-content: center;
  align-items: center;
  justify-content: space-between; */

  display: flex;
  /* margin: 0 10px 0 11px; */
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-right: 63px
}

.tdata {
  display: flex;
  /* margin: 0 10px 0 11px; */
  align-content: center;
  align-items: center;
  justify-content: space-between;

}

.tdMiddle {
  margin-left: 190px;
  padding-right: 45px;
}

/*  table of the row */
.css-ady27e .MuiDataGrid-root {
  /* background: border-box; */
  border-radius: 27px !important;
  border: solid 3px rgb(0 0 0 / 33%) !important;
  margin: 2px !important;
  padding: 10px !important;
}

.css-12te2q9 .MuiDataGrid-root {
  border-radius: 27px !important;
  border: solid 3px rgb(0 0 0 / 33%) !important;
  margin: 2px !important;
  padding: 10px !important;
}

.css-gdm179 .MuiDataGrid-root {
  border-radius: 27px !important;
  border: solid 3px rgb(0 0 0 / 33%) !important;
  margin: 2px !important;
  padding: 10px !important;
}


.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: black !important;
}

.css-1rtnrqa {
  color: black !important;
}

.css-1si6n65 {
  font-size: 1.0rem !important;
}

css-ady27e .MuiDataGrid-footerContainer {
  border-top: none;
  background-color: lightGray;
  border-radius: 15px;
  margin-top: 10px;
}

.css-ady27e .MuiDataGrid-cell {
  border-bottom: 1px solid !important;
}

.css-657leh {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  /* display: flex; */
  background-color: #010144 !important;
  height: 729px !important;
}

.css-ady27e {
  margin: 15px 0 0 0 !important;
  height: 74vh !important;
}

.css-1xn3edc {
  margin-bottom: 0 !important;
}

.css-zwiamn {
  margin: 10px;
}

.css-gdm179 .MuiDataGrid-footerContainer {
  border-top: none;
  background-color: lightGray;
  border-radius: 17px;
  /* margin: 0px; */
  margin-top: 10px;
}

.css-204u17-MuiDataGrid-main {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
}

.css-ahj2mt-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: white !important;
}

.css-9l3uo3 {
  color: white !important;
}

/* .css-657leh {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #010135;
} */

.css-19lnw53-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
  /* color: darkBlue; */
  color: white !important;
  font-weight: 700;
  margin: 10px 0 0 0;
}

.css-iahy47 {
  color: white !important;
}

.css-12u49z-MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: #07111e;
  font-size: 1.0rem !important;

}

.css-ady27e .MuiDataGrid-footerContainer {
  border-radius: 15px;
}

.css-12u49z-MuiDataGrid-root {
  font-size: 1.0rem !important;
}

.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  margin: 4px !important;
}

.message {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: green;
  margin: 26px;
  border: 2px solid green;
  border-radius: 8px;
  font-size: 25px;

}

.message1 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #d00000;
  margin: 30px;
  padding: 11px;
  border: 2px solid #d00000;
  border-radius: 8px;
  font-size: 25px;
}